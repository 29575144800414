import { isNil } from "lodash";
import { denormalize } from "normalizr";
import {
  offer as offerSchema,
  submission as submissionSchema,
  submissionField as submissionFieldSchema
} from "@/store/schema";

export default {
  id: state => state.id,
  isLoading: state => state.isLoading,
  submission: state =>
    state.entities.submission ? state.entities.submission[state.id] : null,
  submissionNormalized: (state, getters) =>
    denormalize(getters.submission, submissionSchema, state.entities),

  //-------------------------------------------------------------------------------------
  //
  //    COMPLETE / INCOMPLETE
  //
  //-------------------------------------------------------------------------------------

  hasSubjects: (state, getters) =>
    getters.submissionNormalized.subjects.length > 0,
  isWaitingForGeocode: (state, getters) =>
    getters.state === "incomplete" && !getters.isGeocoded,
  isWaitingForSubjects: (state, getters) =>
    getters.state === "incomplete" && !getters.hasSubjects,
  isWaitingForValidation: (state, getters) => getters.state === "flagged",
  isWaitingForFields: (state, getters) =>
    getters.state === "incomplete" && !getters.areFieldsFilled,
  areFieldsFilled: (state, getters) =>
    getters.submissionNormalized.fields
      // eslint-disable-next-line no-unused-vars
      .filter(submissionField => {
        // Ideally we'd filter out any un-required questions, but we don't have this data at the moment as I don't
        // feel like adding it right now and like 98% of all questions are required or some other stupidly high
        // percentage.
        return true;
      })
      .every(submissionField => {
        // Return true if EITHER the field has options AND some are selected OR the field value is not nil.
        return (
          (submissionField.options.length > 0 &&
            submissionField.options.some(option => option.isSelected)) ||
          !isNil(submissionField.value)
        );
      }),

  //-------------------------------------------------------------------------------------
  //
  //    STATE
  //
  //-------------------------------------------------------------------------------------
  state: (state, getters) => {
    return getters.submissionNormalized.state;
  },
  isRejected: (state, getters) => getters.state === "rejected",
  isSoftRejected: (state, getters) => getters.state === "soft-rejected",
  isIncomplete: (state, getters) => getters.state === "incomplete",
  isFlagged: (state, getters) => getters.state === "flagged",
  isSeparated: (state, getters) => getters.state === "separated",
  isValidated: (state, getters) => getters.submissionNormalized.isValidated,
  isPostRefined: (state, getters) =>
    !isNil(getters.submissionNormalized.postRefinedAt),
  isRefined: (state, getters) => !isNil(getters.submissionNormalized.refinedAt),
  isGeocoded: (state, getters) =>
    getters.hasAddress && !isNil(getters.address.geocodedAt),
  isPreRefined: (state, getters) =>
    !isNil(getters.submissionNormalized.preRefinedAt),
  isQueued: (state, getters) => getters.state === "queued",
  isDelivered: (state, getters) => getters.state === "delivered",
  isPostProcessed: (state, getters) => getters.state === "processed",
  /**
   * Returns true if the submission is currently still processing.
   * @TODO #1 Nah, how about we check whether all leads are autoclaimed?
   * @TODO #2 Not completely sure what I meant with TODO #1
   *
   * @param state
   * @param getters
   * @returns {boolean}
   */
  isProcessed: (state, getters) =>
    getters.isPostProcessed ||
    (getters.isPreRefined &&
      getters.isGeocoded &&
      getters.isRefined &&
      getters.isValidated &&
      getters.isPostRefined &&
      getters.isSeparated),
  isFallback: (state, getters) => getters.submissionNormalized.isFallback,

  //-------------------------------------------------------------------------------------
  //
  //    ALLOWED GUI STATE
  //
  //-------------------------------------------------------------------------------------

  /**
   * Geocoding is allowed when a submission is pre-refined, but not geocoded yet.
   *
   * @param {Object} state Vuex state object
   * @param {Object} getters Vuex getters object
   * @returns {boolean}
   */
  canGeocode: (state, getters) => getters.isPreRefined && !getters.isGeocoded,
  /**
   * Editing personal details is allowed when a submission is pre-refined, but before any
   * offers are distributed.
   *
   * @param {Object} state Vuex state object
   * @param {Object} getters Vuex getters object
   * @returns {boolean}
   */
  canEditPersonalDetails: (state, getters) =>
    getters.isPreRefined && !getters.hasDistributedOffers,
  /**
   * Editing location details is allowed when a submission is geocoded, but before any offers
   * are distributed.
   *
   * @param {Object} state Vuex state object
   * @param {Object} getters Vuex getters object
   * @returns {boolean}
   */
  canEditLocation: (state, getters) =>
    getters.isGeocoded && !getters.hasDistributedOffers,
  /**
   * Editing subjects is allowed when a submission is pre-refined, but before it is separated.
   *
   * @param {Object} state Vuex state object
   * @param {Object} getters Vuex getters object
   * @returns {boolean}
   */
  canEditSubjects: (state, getters) =>
    getters.isPreRefined && !getters.isSeparated,
  /**
   * Editing the submission fields is allowed when a submission is pre-refined and geocoded,
   * but before any offers are distributed.
   *
   * @param {Object} state Vuex state object
   * @param {Object} getters Vuex getters object
   * @returns {boolean}
   */
  canEditFields: (state, getters) =>
    getters.isPreRefined && getters.isGeocoded && !getters.hasDistributedOffers,
  /**
   * Processing validations (i.e. ignoring or un-ignoring them) is allowed when a submission
   * is validated (otherwise there can't be any validations) and it has no distributed offers.
   *
   * @param {Object} state Vuex state object
   * @param {Object} getters Vuex getters object
   * @returns {boolean}
   */
  canProcessValidations: (state, getters) => {
    return (
      ["validated", "separated", "flagged"].includes(getters.state) &&
      !getters.isRejected &&
      !getters.isSoftRejected &&
      !getters.hasDistributedOffers
    );
  },

  //-------------------------------------------------------------------------------------
  //
  //    VALIDATIONS
  //
  //-------------------------------------------------------------------------------------

  hasValidations: (state, getters) =>
    !isNil(getters.submissionNormalized.validations) &&
    getters.submissionNormalized.validations.length > 0,
  validationsNormalized: (state, getters) =>
    getters.submissionNormalized.validations,
  /**
   *
   * @param {Object} state Vuex state object
   * @param {Object} getters Vuex getters object
   * @returns {Object[]}
   */
  validations: (state, getters) => getters.submissionNormalized.validations,
  hasRejectReason: (state, getters) =>
    !isNil(getters.submissionNormalized.rejectReason),
  rejectReasonNormalized: (state, getters) =>
    getters.submissionNormalized.rejectReason,
  hasRejectComment: (state, getters) =>
    !isNil(getters.submissionNormalized.rejectComment),
  rejectComment: (state, getters) => getters.submissionNormalized.rejectComment,
  /**
   * @deprecated in favor of rejectReasonNormalized
   */
  rejectReason: (state, getters) =>
    state.entities.rejectReason[getters.submission.rejectReason],

  /**
   * A submission is processing whenever it's state is not processed, with TWO notable exceptions:
   * - when it's pre-refined but not geocoded, as it's waiting for user input
   * - when it's validated but invalid, as it's waiting for user input
   *
   * @returns {boolean}
   */
  isProcessing: (state, getters) => {
    // If state is 'rejected', return false.
    if (getters.isRejected) {
      return false;
    }

    // If state is 'soft-rejected', return false.
    if (getters.isSoftRejected) {
      return false;
    }

    // If state is 'queued', return false.
    if (getters.isQueued) {
      return false;
    }

    // If state is 'processed', return false.
    if (getters.isPostProcessed) {
      return false;
    }

    // If state is 'pre-refined', but the submission's address is not geocoded yet, return false as we're waiting for
    // user input.
    if (getters.isWaitingForGeocode) {
      return false;
    }

    // submission is waiting for subject, return false as it's waiting for user input.
    if (getters.isWaitingForSubjects) {
      return false;
    }

    // If the submission has been validated but the submission is not valid, return false as we're waiting for user
    // input.
    if (getters.isWaitingForValidation) {
      return false;
    }

    // If the submission has been post-refined, but all fields have not been filled yet, return false as we're
    // waiting for user input.
    if (getters.isWaitingForFields) {
      return false;
    }

    // Otherwise return true if the state is not 'processed'.
    return getters.state !== "separated";
  },
  //-------------------------------------------------------------------------------------
  //
  //    ADDRESS
  //
  //-------------------------------------------------------------------------------------
  hasAddress: (state, getters) => !isNil(getters.submissionNormalized.address),
  address: (state, getters) => getters.submissionNormalized.address,

  //-------------------------------------------------------------------------------------
  //
  //    TIMESTAMPS
  //
  //-------------------------------------------------------------------------------------

  softRejectedTill: (state, getters) =>
    getters.submissionNormalized.softRejectedTill,
  previousStateAt: (state, getters) => {
    switch (getters.state) {
      case "unprocessed":
        return getters.submissionNormalized.completedAtCorrect;
      case "geocoded":
        return getters.submissionNormalized.preRefinedAt;
      case "refined":
        return getters.submissionNormalized.geocodedAt;
      case "validated":
        return getters.submissionNormalized.refinedAt;
      case "post-refined":
        return getters.submissionNormalized.validatedAt;
      default:
        return undefined;
    }
  },

  //-------------------------------------------------------------------------------------
  //
  //    LOADING / PROCESSING STATE
  //
  //-------------------------------------------------------------------------------------

  /**
   * Returns true if the submission is currently being flagged as spam.
   *
   * @param {Object} state Vuex state object
   * @returns {boolean}
   */
  isFlaggingAsSpam: state => state.processing.flagAsSpam,

  offers: (state, getters) =>
    getters.submissionNormalized.leads.reduce(
      (accumulator, lead) => accumulator.concat(lead.offers || []),
      []
    ),
  rejectedOffers: (state, getters) =>
    getters.offers.filter(o => !isNil(o.rejectedAt)),
  unrejectedOffers: (state, getters) =>
    getters.offers.filter(o => isNil(o.rejectedAt)),
  distributedOffers: (state, getters) =>
    getters.unrejectedOffers.filter(o => !isNil(o.distributedAt)),
  hasOffers: (state, getters) => getters.offers.length !== 0,
  hasUnrejectedOffers: (state, getters) => getters.unrejectedOffers.length > 0,
  hasDistributedOffers: (state, getters) =>
    getters.distributedOffers.length > 0,

  dialogType: state => state.dialog.type,
  dialogProps: state => state.dialog.props,

  // Contact
  fullName: (state, getters) =>
    `${getters.submission.firstName} ${getters.submission.lastName}`,
  originalFullName: (state, getters) =>
    `${getters.submission.originalFirstName} ${getters.submission.originalLastName}`,
  preferredTime: (state, getters) => getters.submission.preferredTime,

  // Meta
  completedAtFormatted: (state, getters) =>
    getters.submission.completedAtFormatted,
  completedAtFormattedToString: (state, getters) =>
    getters.submission.completedAtFormattedToString,
  origin: (state, getters) => getters.submission.origin,
  hasWebsite: (state, getters) => !isNil(getters.submission.website),
  website: (state, getters) =>
    state.entities.website
      ? state.entities.website[getters.submission.website]
      : null,
  hasArticle: (state, getters) => !isNil(getters.submission.article),
  article: (state, getters) =>
    state.entities.article
      ? state.entities.article[getters.submission.article]
      : null,
  hasForm: (state, getters) => !isNil(getters.submission.form),
  form: (state, getters) =>
    getters.hasForm ? state.entities.form[getters.submission.form] : undefined,
  hasBuddy: (state, getters) => !isNil(getters.submission.buddy),
  buddy: (state, getters) => state.entities.buddy[getters.submission.buddy],
  hasBuddyProblems: (state, getters) => !getters.hasBuddy,
  buddyContextClass: (state, getters) =>
    getters.hasBuddyProblems ? "orange lighten-4" : "blue lighten-5",
  subjects: (state, getters) =>
    getters.submission.subjects.map(
      subjectId => state.entities.subject[subjectId]
    ),

  // Location
  addressLine1: (state, getters) =>
    `${getters.address.street} ${getters.address.number}${
      getters.address.box ? ` ${getters.address.box}` : ""
    }`,
  addressLine1Original: (state, getters) =>
    `${getters.originalAddress.street} ${getters.originalAddress.number} ${
      getters.originalAddress.box ? ` ${getters.originalAddress.box}` : ""
    }`,
  addressLine2: (state, getters) =>
    `${getters.address.postalCode} ${getters.address.city}`,
  addressLine2Original: (state, getters) =>
    `${getters.originalAddress.postalCode} ${getters.originalAddress.city}`,
  province: (state, getters) =>
    state.entities.province[getters.submission.province],
  country: (state, getters) =>
    state.entities.country[getters.submission.country],

  // Fields
  field: state => submissionFieldId =>
    denormalize(
      state.entities.submissionField[submissionFieldId],
      submissionFieldSchema,
      state.entities
    ),
  fields: (state, getters) =>
    getters.submission.fields.map(
      fieldId => state.entities.submissionField[fieldId]
    ),
  alwaysFields: (state, getters) =>
    getters.fields.filter(field => field.group === "ALWAYS"),
  subjectFields: (state, getters) =>
    getters.fields.filter(field => field.group === "SUBJECT"),
  contactFields: (state, getters) =>
    getters.fields.filter(field => field.group === "CONTACT"),
  displayableFields: (state, getters) =>
    getters.alwaysFields.concat(getters.subjectFields),
  fieldHasOptions: (state, getters) => submissionFieldId =>
    ["INPUT_RADIO", "SELECT", "INPUT_CHECKBOX"].indexOf(
      getters.field(submissionFieldId).type
    ) > -1,
  fieldOptions: (state, getters) => submissionFieldId =>
    getters
      .field(submissionFieldId)
      .options.map(
        submissionFieldOptionId =>
          state.entities.submissionFieldOption[submissionFieldOptionId]
      ),
  fieldSelectedOptions: (state, getters) => submissionFieldId =>
    getters.fieldOptions(submissionFieldId).filter(option => option.isSelected),

  // Leads
  leads: (state, getters) => getters.submissionNormalized.leads,
  lead: state => leadId => state.entities.lead[leadId],
  isLeadSaturated: (state, getters) => leadId =>
    getters.lead(leadId).offers.length === getters.lead(leadId).maxOfferCount,

  // Offers
  isOfferProcessing: state => offerId =>
    state.offersProcessing.indexOf(offerId) > -1,
  offer: state => offerId =>
    denormalize(state.entities.offer[offerId], offerSchema, state.entities),
  offerFields: (state, getters) => offerId =>
    getters
      .offer(offerId)
      .fields.map(fieldId => state.entities.offerField[fieldId]),
  alwaysOfferFields: (state, getters) => offerId =>
    getters.offerFields(offerId).filter(field => field.group === "ALWAYS"),
  subjectOfferFields: (state, getters) => offerId =>
    getters.offerFields(offerId).filter(field => field.group === "SUBJECT"),
  contactOfferFields: (state, getters) => offerId =>
    getters.offerFields(offerId).filter(field => field.group === "CONTACT"),
  displayableOfferFields: (state, getters) => offerId =>
    getters
      .alwaysOfferFields(offerId)
      .concat(getters.subjectOfferFields(offerId)),
  offerField: state => offerFieldId => state.entities.offerField[offerFieldId],
  offerFieldHasOptions: (state, getters) => offerFieldId =>
    ["INPUT_RADIO", "SELECT", "INPUT_CHECKBOX"].indexOf(
      getters.offerField(offerFieldId).type
    ) > -1,
  offerFieldOptions: (state, getters) => offerFieldId =>
    getters
      .offerField(offerFieldId)
      .options.map(
        offerFieldOptionId =>
          state.entities.offerFieldOption[offerFieldOptionId]
      ),
  offerFieldSelectedOptions: (state, getters) => offerFieldId =>
    getters.offerFieldOptions(offerFieldId).filter(option => option.isSelected),

  // Legacy
  isInvalid: (state, getters) => getters.submission.validations.length > 0,

  isNameChanged: (state, getters) =>
    getters.fullName !== getters.originalFullName,
  isEmailChanged: (state, getters) =>
    getters.submission.originalEmailAddress !== getters.submission.emailAddress,
  isTelephoneNumbersChanged: (state, getters) =>
    getters.submission.originalTelephoneNumbers.join(", ") !==
    getters.submission.telephoneNumbers.join(", "),
  isSubjectAndFieldsShown: (state, getters) =>
    !getters.hasLeads || state.isSubjectAndFieldsShown,
  originalAddress: (state, getters) => getters.submission.originalAddress,
  geocodedAddress: (state, getters) => getters.submission.geocodedAddress,

  isAddressLine1Changed: (state, getters) =>
    getters.addressLine1Original !== getters.addressLine1,

  isAddressLine2Changed: (state, getters) =>
    getters.addressLine2Original !== getters.addressLine2,

  coordinates: (state, getters) => ({
    lat: parseFloat(getters.geocodedAddress.lat),
    lng: parseFloat(getters.geocodedAddress.lng)
  }),

  validationRules: (state, getters) =>
    getters.validations.map(
      submissionValidation =>
        state.entities.validationRule[submissionValidation.validationRule]
    ),

  problems: (state, getters) => {
    const problems = [];
    if (getters.submission.isRejected) {
      problems.push("REJECTED");
    }
    if (!getters.submission.isGeocoded) {
      problems.push("UNGEOCODED");
    }
    if (!getters.submission.isRefined) {
      problems.push("UNREFINED");
    }
    if (!getters.submission.isValidated) {
      problems.push("UNVALIDATED");
    }
    if (getters.isInvalid) {
      problems.push("INVALID");
    }
    if (!getters.hasBuddy) {
      problems.push("UNBUDDIED");
    }
    if (!getters.submission.isSeparated) {
      problems.push("UNSEPARATED");
    }
    return problems;
  },
  firstProblem: (state, getters) =>
    getters.problems.length === 0 ? undefined : getters.problems[0],
  hasProblem: (state, getters) => !isNil(getters.firstProblem),

  canDistribute: (state, getters) => getters.submission.isReadyForDistribution,
  buddySubmissions: (state, getters) =>
    getters.buddy.submissions.map(submissionId => state.entity[submissionId]),

  hasLeads: (state, getters) => getters.submission.leads.length > 0,

  // Lead getters
  leadDefaultSubject: (state, getters) => leadId =>
    state.subject[getters.lead(leadId).defaultSubject],
  leadSubjects: (state, getters) => leadId =>
    getters.lead(leadId).subjects.map(subjectId => state.subject[subjectId]),
  leadHasDefaultSubject: (state, getters) => leadId =>
    !isNil(getters.leadDefaultSubject(leadId)),
  leadSubjectsWithoutDefault: (state, getters) => leadId =>
    getters
      .lead(leadId)
      .subjects.filter(
        subjectId => subjectId !== getters.lead(leadId).defaultSubject
      )
      .map(subjectId => state.subject[subjectId]),
  leadCategories: (state, getters) => leadId =>
    getters
      .lead(leadId)
      .categories.map(categoryId => state.category[categoryId]),
  isLeadOffered: (state, getters) => leadId =>
    getters.lead(leadId).offers.length > 0,

  // Lead Offers getters
  leadOffers: (state, getters) => leadId =>
    state.entities.lead[leadId].offers.map(offerId => getters.offer(offerId)),
  leadUnrejectedOffers: (state, getters) => leadId =>
    getters.leadOffers(leadId).map(offer => isNil(offer.rejectedAt)),
  leadOffersFilterIds: (state, getters) => leadId =>
    getters
      .leadOffers(leadId)
      .forEach(offer =>
        isNil(offer.bulditFilter)
          ? [offer.filter]
          : [offer.filter, offer.bulditFilter]
      ),
  leadOffer: state => offerId => state.offer[offerId],
  leadOfferCompany: (state, getters) => offerId =>
    state.company[getters.leadOffer(offerId).company],
  leadOfferState: (state, getters) => offerId => {
    if (!isNil(getters.leadOffer(offerId).rejectedAt)) {
      return "REJECTED";
    } else if (
      !isNil(getters.leadOffer(offerId).rejectionRequestedAt) &&
      isNil(getters.leadOffer(offerId).rejectionDeniedAt)
    ) {
      return "REJECTION_REQUESTED";
    } else if (!isNil(getters.leadOffer(offerId).distributedAt)) {
      return "SENT";
    } else {
      return "UNSENT";
    }
  },
  leadHasExclusiveOffer: (state, getters) => leadId =>
    getters.leadUnrejectedOffers(leadId).filter(offer => offer.isExclusive)
      .length > 0,
  leadIsSaturated: (state, getters) => leadId =>
    getters.leadUnrejectedOffers(leadId).length === 3 ||
    getters.leadHasExclusiveOffer(leadId),

  // Lead field getters
  leadFields: (state, getters) => leadId =>
    getters.lead(leadId).fields.map(fieldId => state.leadField[fieldId]),
  leadAlwaysFields: (state, getters) => leadId =>
    getters.leadFields(leadId).filter(field => field.group === "ALWAYS"),
  leadSubjectFields: (state, getters) => leadId =>
    getters.leadFields(leadId).filter(field => field.group === "SUBJECT"),
  leadContactFields: (state, getters) => leadId =>
    getters.leadFields(leadId).filter(field => field.group === "CONTACT"),
  leadDisplayableFields: (state, getters) => leadId =>
    getters.leadAlwaysFields(leadId).concat(getters.leadSubjectFields(leadId)),
  whenField: (state, getters) => leadId =>
    getters
      .leadAlwaysFields(leadId)
      .filter(field => field.type === "INPUT_RADIO"),
  descriptionField: (state, getters) => leadId =>
    getters.leadAlwaysFields(leadId).filter(field => field.type === "TEXTAREA"),
  leadField: state => leadFieldId => state.leadField[leadFieldId],
  leadFieldHasOptions: (state, getters) => leadFieldId =>
    ["INPUT_RADIO", "SELECT", "INPUT_CHECKBOX"].indexOf(
      getters.leadField(leadFieldId).type
    ) > -1,
  leadFieldIsMultiple: (state, getters) => leadFieldId =>
    getters.leadFieldHasOptions(leadFieldId) &&
    getters.leadField(leadFieldId).type === "INPUT_CHECKBOX",
  leadFieldOptions: (state, getters) => leadFieldId =>
    getters
      .leadField(leadFieldId)
      .options.map(optionId => state.leadFieldOption[optionId]),
  leadFieldSelectedOptions: (state, getters) => leadFieldId =>
    getters.leadFieldOptions(leadFieldId).filter(option => option.isSelected)
};
